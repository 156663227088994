<template>
  <div>
    <div id="content">
      <div id="loading" v-if="loading">
        <div class="dot-flashing"></div>
      </div>
      <div class="wrapper">
        <header>
          <h1>Dépôt massif</h1>
        </header>
        <div>
          <router-link
            :to="{name: 'MassiveUpload', params: { slug: '0' }}"
            class="btn btn-primary float-right mt-4 text-white"
          >
            <i class="fa fa-plus"></i>
            <span>Nouveau</span>
          </router-link>

          <p v-if="errors.length">
            <v-alert
              text
              dense
              type="error"
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ error.title }}
            </v-alert>
          </p>

          <div class="table-responsive col-12">
            <table class="mdl-data-table mdl-js-data-table mdl-data-table--selectable mdl-table-custom sm-2 table-logs">
              <thead>
              <tr>
                <th>Libellé de la tâche</th>
                <th>Libellé du document dans le coffre</th>
                <th>Situation</th>
                <th>Rollback</th>
                <th>Section</th>
                <th>Date de création</th>
                <th>Créé par</th>
                <th>Télécharger</th>
              </tr>
              </thead>
              <tbody v-if="$store.state.massiveUpload.list.length > 0">
                <tr v-for="(row, rowIndex) in $store.state.massiveUpload.list" :key="rowIndex">
                  <td data-label="Libellé de la tâche :">{{ row.title }}</td>
                  <td data-label="Libellé du document dans le coffre :">{{ row.documentNameVault }}</td>
                  <td data-label="Situation :">{{ row.statusName }}</td>
                  <td data-label="Rollback :" class="actions">
                    <v-btn plain v-if="isRollbackable(row)" title="Rollback" @click="confirmRollback(rowIndex)">
                      <i class="fa fa-undo text-primary"></i>
                    </v-btn>
                  </td>
                  <td data-label="Section :">{{ row.section }}</td>
                  <td data-label="Date de création :">{{ row.createdAt | dateFormat }} {{ row.createdAt | hourFormat }}</td>
                  <td data-label="Créé par :">{{ row.createdByName }}</td>
                  <td class="actions" data-label="Télécharger :">
                    <button title="Télécharger CSV" @click="downloadDocument({data: row, type: 'csv'})">
                      <i class="fas fa-download"></i>
                    </button>
                    <button title="Télécharger Document" @click="downloadDocument({data: row, type: 'document'})">
                      <i class="fas fa-download"></i>
                    </button>
                    <button v-if="row.hasSuccessLog" title="Télécharger SuccessLog" @click="downloadDocument({data: row, type: 'success'})">
                      <i class="fas fa-download"></i>
                    </button>
                    <button v-if="row.hasSuccessLog" title="Télécharger ErrorLog" @click="downloadDocument({data: row, type: 'error'})">
                      <i class="fas fa-download"></i>
                    </button>
                    <button v-if="row.hasRollbackLog" title="Télécharger RollbackLog" @click="downloadDocument({data: row, type: 'rollback'})">
                      <i class="fas fa-download"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <td colspan="100%">
                  <h5 class="text-center ma-0">Aucun dépôt.</h5>
                </td>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <RollbackDialog
      v-if="isConfirmDialogOpen !== false"
      :dialog-state="isConfirmDialogOpen"
      :indexRollback="indextoRollback"
      @closeRollbackDialog="isConfirmDialogOpen = false"
      @saveRollback="rollback"
    ></RollbackDialog>
  </div>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout'
import config from '@/config'
import Vue from 'vue'
import FileSaver from 'file-saver'
import RollbackDialog from '@/components/Dialog/MassiveUpload/RollbackDialog'

export default {
  name: 'MassiveUploadList',
  components: { RollbackDialog },
  data: () => ({
    successMessage: null,
    errors: [],
    loading: false,
    isConfirmDialogOpen: false,
    indextoRollback: null,
    xTarget: null
  }),
  created () {
    this.$emit('update:layout', DefaultLayout)
    document.title = config.title + ' - Dépôt massif'
    this.$store.dispatch('massiveUpload/getData')
    this.xTarget = localStorage.getItem('x_target')
  },
  methods: {
    isRollbackable (massiveUpload) {
      const timestamp = Date.parse(massiveUpload.createdAt)
      const createdAt = new Date(timestamp)
      const now = new Date()

      const rollbackDeadline = new Date(now.getTime() - 14 * 24 * 60 * 60 * 1000)
      
      return massiveUpload.status === 'D' && createdAt >= rollbackDeadline
    },
    confirmRollback (index) {
      this.isConfirmDialogOpen = true
      this.indextoRollback = index
    },
    rollback () {
      // alias of item to rollback
      const item = this.$store.state.massiveUpload.list[this.indextoRollback]

      this.isConfirmDialogOpen = false
      this.loading = true
      const options = {
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken,
          'X-Target': this.xTarget
        },
        params: {
          _method: 'PUT'
        }
      }

      Vue.prototype.$http
        .post(config.apiUrl + '/massiveuploads/' + item.id + '/rollback', null, options)
        .then(response => {
          // this.$router.push({ name: 'MassiveUploadList' })
          this.loading = false
        })
        .catch(e => {
          const response = e.response
          this.loading = false

          if (response.status === 400) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          if (response.status === 403) {
            this.$store.dispatch('logout', 'rejected')
          }
        })
    },
    downloadDocument ({ data, type }) {
      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/massiveupload/' + data.id + '/' + type, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken,
            'X-Target': data.server
          },
          responseType: 'blob'
        })
        .then(response => {
          if (response.status === 200) {
            const headers = response.headers
            let filename = document.name + '.' + document.extension
            if (headers['content-name']) {
              filename = decodeURIComponent(escape(headers['content-name']))
            }
            const blob = new Blob([response.data], { type: headers['content-type'] })
            FileSaver.saveAs(blob, filename)

            this.loading = false
          }
        })
        .catch(e => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn .text-white {
  opacity: 1;
}

.btn-circle.btn-sm {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 4px 0 0 7px;

  i {
    font-size: 1em;
  }
}
</style>
