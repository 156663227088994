<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialogState"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Validation de rollback</span>
        </v-card-title>
        <v-container>
          <v-card-text>

              <v-row>
                <v-col cols="12">
                  <strong>Libellé de la tâche: </strong>{{$store.state.massiveUpload.list[indexRollback].title}}
                </v-col>
                <v-col cols="12">
                  <strong>Libellé du document dans le coffre: </strong>{{$store.state.massiveUpload.list[indexRollback].documentNameVault}}
                </v-col>
                <v-col cols="12">
                  <strong>Section: </strong>{{$store.state.massiveUpload.list[indexRollback].section}}
                </v-col>
                <v-col cols="12">
                  <strong>Date de création: </strong>
                  {{$store.state.massiveUpload.list[indexRollback].createdAt | dateFormat}} {{$store.state.massiveUpload.list[indexRollback].createdAt | hourFormat}}
                </v-col>
                <v-col cols="12">
                  <strong>Créé par: </strong>{{$store.state.massiveUpload.list[indexRollback].createdByName}}
                </v-col>
              </v-row>

          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="$emit('closeRollbackDialog')"
          >
            Annuler
          </v-btn>

          <button type="button" @click="$emit('saveRollback')" class="v-btn v-btn--flat v-btn--text theme--light v-size--default blue--text text--darken-1">
            Valider
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'MassiveUploadDialog',
  props: {
    dialogState: Boolean,
    indexRollback: Number
  }
}
</script>
